<template>
  <div class="organ-management-page" v-loading="loading">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="客户姓名:">
              <el-input v-model="searchFrom.medicalName" :clearable="true" placeholder="请输入医护姓名"></el-input>
            </el-form-item>
            <el-form-item label="客户电话:">
              <el-input v-model="searchFrom.customerName" :clearable="true" placeholder="请输入客户电话" oninput = "value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="医护姓名:">
              <el-input v-model="searchFrom.customerAccount" :clearable="true" placeholder="请输入医护姓名"></el-input>
            </el-form-item>
            <el-form-item label="预约类型:">
              <el-select v-model="searchFrom.region" placeholder="请选择预约类型">
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="objs.dictionaryName"
                  :value="objs.dictionaryCode"
                  v-for="objs in typelist"
                  :key="objs.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="queryList" icon="el-icon-search"  :disabled="disabledFalg">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card" style="margin-top:20px;">
      <!-- <div class="table-block"> -->
        <el-table
          :data="tableData"
          :header-cell-style="{ 'text-align': 'center', background: '#EEF1FC', color: '#999' }"
          :cell-style="{ 'text-align': 'center' }"
          height="532"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="50"></el-table-column> -->
          <el-table-column prop="customName" label="客户姓名"> </el-table-column>
          <el-table-column prop="samplingName" label="预约类型"> </el-table-column>
          <el-table-column prop="tasktypeName" label="预约方式"> </el-table-column>
          <el-table-column prop="tel1" label="手机号"> </el-table-column>
          <el-table-column prop="taskName" label="产品类型"> </el-table-column>
          <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button @click="addNew(scope.row, 2)" type="text" size="small" class="el_btn">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      <!-- </div> -->
      <!-- 页码 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[ 10, 20, 30, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
    <!-- 详情页弹框 -->
    <toview @closeToView="closeToView" v-if="showToView" :addNewdata="addNewdatas"></toview>
  </div>
</template>

<script>
import toview from '@/views/appointmentManagement/components/toview'
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      searchFrom: {
        medicalName: '',
        customerName: '',
        customerAccount: '',
        teamName: '',
        region: '',
      },
      addNewdatas: '',
      tableData: [],
      multipleSelection: [],
      showToView: false,
      loading: true,
      currentPage: 1,
      total: 0,
      pagesize: 10,
      typelist: '',
      disabledFalg:false
    }
  },
  components: { toview },
  created() {
    this.loaddata(this.pagesize, this.currentPage)
  },
  methods: {
    // 获取页面数据方法
    loaddata(pageSize, pageNo, doctorName, customName, customTel) {
      postRequestAnthor('/planmanage/querySamplingInfoPageList', {
        status: [6, 7, 8],
        pageSize: pageSize,
        pageNo: pageNo,
        doctorName: doctorName,
        customName: customName,
        customTel: customTel,
      })
        .then(res => {
          console.log(res.data);
          this.tableData = res.data
          this.loading = false
          this.total = res.count
        })
        .catch(() => {
          this.loading = false
        })
      // 获取预约类型
      postRequestAnthor('dict/getDictByCategory', { dictionaryCategoryCode: '07' }).then(res => {
        this.typelist = res
        this.typelist.forEach((item,index)=>{
          if (item.dictionaryCode == '076') {       
             this.typelist.splice(index,1)
          }
        })
        console.log(res)
      })
    },
    // 每页数据条数
    handleSizeChange(val) {
      this.loading = true
      this.pagesize = val
      this.loaddata(
        this.pagesize,
        this.currentPage,
        this.searchFrom.medicalName,
        this.searchFrom.customerName,
        this.searchFrom.customerAccount,
      )
    },
    // 当前页
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val
      this.loaddata(
        this.pagesize,
        this.currentPage,
        this.searchFrom.medicalName,
        this.searchFrom.customerName,
        this.searchFrom.customerAccount,
      )
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 详情
    addNew(row) {
      this.showToView = true
      this.addNewdatas = row
    },
    // 弹框页面关闭触发
    closeToView() {
      this.showToView = false
    },
    // 查询
    queryList() {
      // console.log(
      //   // this.searchFrom.customerAccount,
      //   this.searchFrom.medicalName,
      //   this.searchFrom.customerName,
      //   this.pagesize,
      //   this.currentPage,
        // this.searchFrom.region,
      // )
      this.loading = true
      this.disabledFalg = true
      setTimeout(()=>{
          this.disabledFalg = false
        },2000)
      postRequestAnthor('/planmanage/querySamplingInfoPageList', {
        status: [6, 7, 8],
        doctorName: this.searchFrom.customerAccount,
        customerName: this.searchFrom.medicalName,
        customerTel: this.searchFrom.customerName,
        pageSize: '10',
        pageNo: '1',
        tasktypeCode: this.searchFrom.region,
      }).then(res => {
        console.log(res);
        this.tableData = res.data
        this.total = res.count
        this.loading = false
      }).catch(()=>{
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.search > .el-form-item {
  margin-right: 16px !important;
}
.organ-management-page {
  background: transparent;
}
.el-form-item__label {
  padding: 0px !important;
}
</style>
