<template>
  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" width="65%" @close="handleClose" v-loading="loading" :showClose="showclose">
    <div class="organ-management-page">
      <el-card style="margin-bottom: 10px;">
        <div class="toview_top_container">
          <el-col :span="3" style="text-align: center">
            <img :src="imgsrc" alt="" class="toview_top_container_img" />
          </el-col>
          <el-col :span="7">
            <p>
              <span>姓名:</span><span>&emsp;{{ datalist.customName }}</span>
            </p>
            <p>
              <span>主治医师:</span><span>&emsp;{{ datalist.doctorName }}</span>
            </p>
          </el-col>
          <el-col :span="7">
            <p>
              <span>手机号:</span><span>&emsp;{{ datalist.tel1 }}</span>
            </p>
            <p>
              <span>营养师:</span><span>&emsp;{{ datalist.dietitianName }}</span>
            </p>
          </el-col>
          <el-col :span="7">
            <p>
              <span>产品类型:</span><span>&emsp;{{ datalist.taskName }}</span>
            </p>
            <p>
              <span>运动师:</span><span>&emsp;{{ datalist.dietitianName }}</span>
            </p>
          </el-col>
        </div>
      </el-card>
      <!-- <el-card style="margin-bottom: 20px"> -->
      <div :inline="true" class="demo-form-inline searchFrom" size="small">
        <el-menu :default-active="activeIndex" mode="horizontal" ref="kzMenu">
          <el-menu-item index="1" @click="changeType(1)" v-if="datalist.catalogCode != 'TJ'">医生方案</el-menu-item>
          <el-menu-item index="2" @click="changeType(2)" v-if="datalist.catalogCode != 'TJ'">运动师方案</el-menu-item>
          <el-menu-item index="3" @click="changeType(3)" v-if="datalist.catalogCode != 'TJ'">营养师方案</el-menu-item>
          <el-menu-item index="4" @click="changeType(4)" v-if="datalist.catalogCode != 'TJ'">当期检查报告</el-menu-item>
          <el-menu-item index="5" @click="changeType(5)" v-if="datalist.catalogCode == 'TJ' && datalist.tasktypeCode != '071'">体检报告</el-menu-item>
          <el-menu-item index="6" @click="changeType(6)" v-if="datalist.catalogCode == 'TJ'">{{datalist.tasktypeCode != '071'?'体检结果方案':'体检方案'}}</el-menu-item>
        </el-menu>
      </div>
      <!-- </el-card> -->
      <el-card style="margin-top:10px;">
        <div class="toview_main">
          <!-- 医生方案 -->
          <el-row class="row" v-show="type == 1">
            <el-row class="row_head">
              <el-col :span="6">出具方案时间：</el-col>
              <!-- <el-col :span="5">方案类型：</el-col> -->
              <el-col :span="6">备注：</el-col>
              <el-col :span="3" style="text-align: center">PDF</el-col>
              <el-col :span="3" style="text-align: center">视频</el-col>
              <el-col :span="3" style="text-align: center">视频封面</el-col>
              <el-col :span="3" style="text-align: center">操作</el-col>
            </el-row>
            <!-- 新增方案 -->
            <div class="row_add">
              <el-row v-show="showflag">
                <el-col :span="24" style="text-align: center; border: 0">
                  <el-button type="primary" plain @click="showclick"><i class="el-icon-plus"></i> 新增方案</el-button>
                </el-col>
              </el-row>
              <el-row v-show="!showflag">
                <el-col :span="6">
                  <el-date-picker v-model="DateTime" type="datetime" placeholder="选择日期" style="width: 80%" value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-col>
                <el-col :span="6">
                  <el-input style="width: 80%" v-model="Remarks" placeholder="备注"></el-input>
                </el-col>
                <el-col :span="3" style="display: flex;justify-content: center;">
                  <iframe :src="iframesrc" alt="" frameborder="0" v-if="iframesrc" scrolling="no" />
                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="doctorprogress" :show-file-list="falg" style="text-align: center"
                    v-loading="doctorloading">
                    <el-button size="small" type="text" style="margin-right: 10px;text-align: center" v-show="pdfflagshow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px;text-align: center" v-show="!pdfflagshow" @click.capture="iframeupload">预览</el-button>
                </el-col>
                <el-col :span="3" style="display: flex;justify-content: center;">
                  <video :src="videosrc" v-if="videosrc" style="height: 40px"></video>
                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="doctor_progress" :show-file-list="falg" style="text-align: center"
                    v-loading="doctor_loading">
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="flagshow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!flagshow" @click="videoupload">预览</el-button>
                </el-col>
                <el-col :span="3" style="display: flex;justify-content: center;">
                  <img :src="imagesSrc" v-if="imagesSrc" style="height: 40px">
                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="videoCover_progress" :show-file-list="falg" style="text-align: center"
                    v-loading="videoCover_loading" :disabled="imgdisabled">
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="videoCovershow" :disabled="imgdisabled">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!videoCovershow" @click="videoCoverupload">预览</el-button>
                </el-col>
                <el-col :span="3" style="text-align: center">
                  <el-button type="text" @click="determine">保存</el-button>
                  <el-button type="text" @click="empty">取消</el-button>
                </el-col>
              </el-row>
            </div>

            <!-- <p>历史方案：</p> -->
            <div class="toview_main_history">
              <div class="toview_main_doctor" v-for="obj in datalist.doctorPlan" :key="obj.id">
                <el-row>
                  <el-col :span="6">{{ obj.checkTime }}</el-col>
                  <el-col :span="6" style="padding-right: 30px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ obj.remark }}</el-col>
                  <el-col :span="3" style="text-align: center; display: flex" class="history_media">
                    <iframe :src="obj.pdf" alt="" frameborder="0" scrolling="no" ref="iframe" />
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.pdf" @click="obj.pdf?iframeclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: center; display: flex" class="history_media">
                    <video :src="obj.video"></video>
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.video" @click="obj.video?videoclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: center; display: flex" class="history_media">
                    <img :src="obj.videoCover" v-show="obj.videoCover">
                    <div v-show="!obj.videoCover" style="width:10px;height:10px;"></div>
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.videoCover" @click="obj.videoCover?videoCoverclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: center">
                    <el-button type="text" size="mini" @click="Delete(obj)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-row>
          <!-- 运动师方案 -->
          <el-row class="row" v-show="type == 2">
            <el-row class="row_head">
              <el-col :span="6">出具方案时间：</el-col>
              <!-- <el-col :span="5">方案类型：</el-col> -->
              <el-col :span="6">备注：</el-col>
              <el-col :span="3" style="text-align: center">PDF</el-col>
              <el-col :span="3" style="text-align: center">视频</el-col>
              <el-col :span="3" style="text-align: center;">视频封面</el-col>
              <el-col :span="3" style="text-align: center;">操作</el-col>
            </el-row>
            <!-- 新增方案 -->
            <div class="row_add">
              <el-row v-show="showflag">
                <el-col :span="24" style="text-align: center; border: 0">
                  <el-button type="primary" plain @click="showclick"><i class="el-icon-plus"></i> 新增方案</el-button>
                </el-col>
              </el-row>
              <el-row v-show="!showflag">
                <el-col :span="6">
                  <el-date-picker v-model="DateTime" type="datetime" placeholder="选择日期" style="width: 60%" value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-col>
                <el-col :span="6">
                  <el-input style="width: 60%" v-model="Remarks" placeholder="备注"></el-input>
                </el-col>
                <el-col :span="3" style="display: flex;justify-content: center;">
                  <div @click.capture="iframeupload" ref="iframe">
                    <iframe :src="iframesrc" alt="" frameborder="0" v-if="iframesrc" scrolling="no" />
                  </div>
                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="doctorprogress" :show-file-list="falg" style="text-align: center"
                    v-loading="doctorloading">
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="pdfflagshow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!pdfflagshow" @click.capture="iframeupload">预览</el-button>
                </el-col>
                <el-col :span="3" style="display: flex;justify-content: center;">
                  <video :src="videosrc" v-if="videosrc" @click="videoupload" style="height: 40px"></video>

                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="doctor_progress" :show-file-list="falg" style="text-align: center"
                    v-loading="doctor_loading">
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="flagshow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!flagshow" @click="videoupload">预览</el-button>
                </el-col>
                <el-col :span="3" style="display: flex;justify-content: center;">
                  <img :src="imagesSrc" v-if="imagesSrc" style="height: 40px">
                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="videoCover_progress" :show-file-list="falg" style="text-align: center"
                    v-loading="videoCover_loading">
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="videoCovershow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!videoCovershow" @click="videoCoverupload">预览</el-button>
                </el-col>
                <el-col :span="3" style="text-align: center">
                  <el-button type="text" @click="determine">保存</el-button>
                  <el-button type="text" @click="empty">取消</el-button>
                </el-col>
              </el-row>
            </div>

            <!-- <p>历史方案：</p> -->
            <div class="toview_main_history">
              <div class="toview_main_doctor" v-for="obj in datalist.fitcouchPlan" :key="obj.id">
                <el-row>
                  <el-col :span="6">{{ obj.checkTime }}</el-col>
                  <el-col :span="6" style="padding-right: 30px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ obj.remark }}</el-col>
                  <el-col :span="3" style="text-align: center; display: flex" class="history_media">
                    <!-- <div @click.capture="iframeclick(obj)" id="iframeId"> -->
                    <iframe :src="obj.pdf" alt="" frameborder="0" scrolling="no" />
                    <!-- </div> -->
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.pdf" @click="obj.pdf?iframeclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: center; display: flex" class="history_media">
                    <video :src="obj.video" @click="obj.video?videoclick(obj):''"></video>
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.video" @click="obj.video?videoclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: center; display: flex" class="history_media">
                    <img :src="obj.videoCover" v-show="obj.videoCover">
                    <div v-show="!obj.videoCover" style="width:10px;height:10px;"></div>
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.videoCover" @click="obj.videoCover?videoCoverclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: center">
                    <!-- <el-button @click="historysee(obj)">查看</el-button> -->
                    <el-button type="text" size="mini" @click="Delete(obj)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-row>
          <!-- 营养师方案 -->
          <el-row class="row" v-show="type == 3">
            <el-row class="row_head">
              <el-col :span="6">出具方案时间：</el-col>
              <!-- <el-col :span="5">方案类型：</el-col> -->
              <el-col :span="6">备注：</el-col>
              <el-col :span="3" style="text-align: center">PDF</el-col>
              <el-col :span="3" style="text-align: center">视频</el-col>
              <el-col :span="3" style="text-align: center;">视频封面</el-col>
              <el-col :span="3" style="text-align: center;">操作</el-col>
            </el-row>
            <!-- 新增方案 -->
            <div class="row_add">
              <el-row v-show="showflag">
                <el-col :span="24" style="text-align: center; border: 0">
                  <el-button type="primary" plain @click="showclick"><i class="el-icon-plus"></i> 新增方案</el-button>
                </el-col>
              </el-row>
              <el-row v-show="!showflag">
                <el-col :span="6">
                  <el-date-picker v-model="DateTime" type="datetime" placeholder="选择日期" style="width: 60%" value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-col>
                <el-col :span="6">
                  <el-input style="width: 60%" v-model="Remarks" placeholder="备注"></el-input>
                </el-col>
                <el-col :span="3" style="display: flex;justify-content: center;">
                  <div @click.capture="iframeupload" ref="iframe">
                    <iframe :src="iframesrc" alt="" frameborder="0" v-if="iframesrc" scrolling="no" />
                  </div>
                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="doctorprogress" :show-file-list="falg" style="text-align: center"
                    v-loading="doctorloading">
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="pdfflagshow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!pdfflagshow" @click.capture="iframeupload">预览</el-button>
                </el-col>
                <el-col :span="3" style="display: flex;justify-content: center;">
                  <video :src="videosrc" v-if="videosrc" @click="videoupload" style="height: 40px"></video>

                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="doctor_progress" :show-file-list="falg" style="text-align: center"
                    v-loading="doctor_loading">
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="flagshow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!flagshow" @click="videoupload">预览</el-button>
                </el-col>
                <el-col :span="3" style="display: flex;justify-content: center;">
                  <img :src="imagesSrc" v-if="imagesSrc" style="height: 40px">
                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="videoCover_progress" :show-file-list="falg" style="text-align: center"
                    v-loading="videoCover_loading">
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="videoCovershow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!videoCovershow" @click="videoCoverupload">预览</el-button>
                </el-col>
                <el-col :span="3" style="text-align: center">
                  <el-button type="text" @click="determine">保存</el-button>
                  <el-button type="text" @click="empty">取消</el-button>
                </el-col>
              </el-row>
            </div>

            <!-- <p>历史方案：</p> -->
            <div class="toview_main_history">
              <div class="toview_main_doctor" v-for="obj in datalist.dietitianPlan" :key="obj.id">
                <el-row>
                  <el-col :span="6">{{ obj.checkTime }}</el-col>
                  <el-col :span="6" style="padding-right: 30px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ obj.remark }}</el-col>
                  <el-col :span="3" style="text-align: center; display: flex" class="history_media">
                    <!-- <div @click.capture="iframeclick(obj)" id="iframeId"> -->
                    <iframe :src="obj.pdf" alt="" frameborder="0" scrolling="no" ref="iframe" />
                    <!-- </div> -->
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.pdf" @click="obj.pdf?iframeclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: center; display: flex" class="history_media">
                    <video :src="obj.video" @click="obj.video?videoclick(obj):''"></video>
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.video" @click="obj.video?videoclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: center; display: flex" class="history_media">
                    <img :src="obj.videoCover" v-show="obj.videoCover">
                    <div v-show="!obj.videoCover" style="width:10px;height:10px;"></div>
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.videoCover" @click="obj.videoCover?videoCoverclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: center">
                    <!-- <el-button @click="historysee(obj)">查看</el-button> -->
                    <el-button type="text" size="mini" @click="Delete(obj)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-row>
          <!--当期检查结果  -->
          <el-row class="row" v-show="type == 4">
            <el-row class="row_head">
              <el-col :span="8">出具方案时间：</el-col>
              <el-col :span="8">{{inspectFlag? '报告名称：': '检测项目：'}}</el-col>
              <el-col :span="4" style="text-align: center;">PDF</el-col>
              <el-col :span="4" style="text-align: center;">操作</el-col>
            </el-row>
            <!-- &nbsp;&nbsp;上传报告 -->
            <el-row v-show="showflag">
              <el-col :span="24" style="text-align: center; border: 0;margin: 8px 0">
                <el-button type="primary" plain @click="showclick"><i class="el-icon-plus"></i> &nbsp;&nbsp;上传报告</el-button>
              </el-col>
            </el-row>
            <div class="row_result" style="margin: 8px 0" v-show="!showflag">
              <el-row style="flex: 6.14;padding:0 6px">
                <el-col :span="12">检查时间：<el-date-picker v-model="DateTime" type="datetime" placeholder="选择日期" style="width: 60%" value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-col>
                <el-col :span="12" v-if="inspectFlag">报告名称：
                  <el-input style="width: 60%" v-model="inspectRemarks" placeholder="报告名称"></el-input>
                </el-col>
                <el-col :span="12" v-if="!inspectFlag">检查项目：
                  <el-select v-model="inspectRemarks" placeholder="请选择检测项目" style="width: 70%">
                    <el-option v-for="item in inspectlist" :key="item.value" :label="item.dictionaryName" :value="item.dictionaryCode">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row style="flex: 3.06; text-align: center">
                <el-col :span="11" style="display: flex; justify-content: center;">
                  <!-- <video :src="fileurl" v-if="(fileurl.slice(fileurl.lastIndexOf('.')) == '.mp4')" @click="videoupload" style="height:40px"></video> -->
                  <div @click.capture="iframeupload" ref="iframe">
                    <iframe :src="iframesrc" alt="" frameborder="0" v-if="iframesrc" scrolling="no" />
                  </div>
                  <el-upload class="upload-demo" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="doctorprogress" :show-file-list="falg" style="text-align: center"
                    v-loading="doctorloading">
                    <el-button size="small" type="text" style="margin-right: 10px;" v-show="pdfflagshow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!pdfflagshow" @click.capture="iframeupload">预览</el-button>
                </el-col>
                <el-col :span="13" style="text-align: center">
                  <el-button type="text" @click="determine">保存</el-button>
                  <el-button type="text" @click="empty">取消</el-button>
                </el-col>
              </el-row>
            </div>
            <!-- <p>历史方案：</p> -->
            <div class="toview_main_history">
              <div class="toview_main_doctor" v-for="obj in datalist.checkResult" :key="obj.id">
                <el-row>
                  <el-col :span="8">{{ obj.checkTime }}</el-col>
                  <el-col :span="8"  v-if="inspectFlag">{{ obj.reportName? obj.reportName:'检测报告'}}</el-col>
                  <el-col :span="8"  v-if="!inspectFlag">
                    <select v-model="obj.schemeType" disabled style="appearance: none; border: 0; color: #000">
                      <option v-for="item in typelist" :key="item.value" :label="item.dictionaryName" :value="item.dictionaryCode"></option>
                    </select>
                  </el-col>
                  <!-- <el-col
                    :span="5"
                    style="padding-right: 30px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                    >{{ obj.remark }}</el-col
                  > -->
                  <el-col :span="4" class="history_media">
                    <!-- <div @click.capture="iframeclick(obj)" id="iframeId"> -->
                    <iframe :src="obj.pdf" alt="" frameborder="0" scrolling="no" />
                    <!-- </div> -->
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.pdf" @click="obj.pdf?iframeclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="4" style="text-align: center;">
                    <!-- <el-button @click="historysee(obj)">查看</el-button> -->
                    <el-button type="text" size="mini" @click="Delete(obj)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-row>
          <!-- 体检报告和体检方案 -->
          <el-row class="row" v-show="type ==5 || type == 6">
            <el-row class="row_head">
              <el-col :span="6">{{type ==5?'出具报告时间：':'出具方案时间：'}}</el-col>
              <el-col :span="6">{{type ==5?'报告名称：':'方案名称：'}}</el-col>
              <el-col :span="6" v-if="type ==6 && datalist.tasktypeCode == '071'">方案价格：</el-col>
              <!-- <el-col :span="6">出具报告进展：</el-col> -->
              <el-col :span="type ==6 && datalist.tasktypeCode == '071'?3:6" style="text-align: center">PDF</el-col>
              <el-col :span="type ==6 && datalist.tasktypeCode == '071'?3:6" style="text-align: center;">操作</el-col>
            </el-row>
            <!-- 新增方案 -->
            <el-row v-show="showflag">
              <el-col :span="24" style="text-align: center; border: 0;margin:10px 0">
                <el-button type="primary" plain @click="showclick"><i class="el-icon-plus"></i>{{type ==5?'新增报告':'上传方案'}}</el-button>
              </el-col>
            </el-row>
            <div class="row_add" v-show="!showflag">
              <el-row>
                <el-col :span="6">
                  <el-date-picker v-model="DateTime" type="datetime" placeholder="选择日期" style="width: 60%" value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-col>
                <el-col :span="6">
                  <el-input style="width: 60%" v-model="reportName" :placeholder="type ==5?'报告名称':'方案名称'"></el-input>
                </el-col>
                <el-col :span="6" v-if="type ==6 && datalist.tasktypeCode == '071'">
                  <el-input style="width: 60%" v-model="planPrice" placeholder="方案价格"></el-input>
                </el-col>
                <!-- <el-col :span="6">
                  <el-select v-model="reportOnProgress" clearable placeholder="请选择报告进展">
                    <el-option label="部分上传" :value="1" />
                    <el-option label="全部上传" :value="2" />
                  </el-select>
                </el-col> -->
                <el-col :span="type ==6 && datalist.tasktypeCode == '071'?3:6" style="display: flex;justify-content: center;">
                  <div @click.capture="iframeupload" ref="iframe">
                    <iframe :src="iframesrc" alt="" frameborder="0" v-if="iframesrc" scrolling="no" />
                  </div>
                  <el-upload class="upload-demo" :before-upload="fileBeforeAvatarUpload" :action="fileuploadac" :on-success="uploadsuccess" :on-error="uploaderror" :on-progress="doctorprogress" :show-file-list="falg"
                    style="text-align: center" v-loading="doctorloading">
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="pdfflagshow">&nbsp;&nbsp;上传</el-button>
                  </el-upload>
                  <el-button size="small" type="text" style="margin-right: 10px" v-show="!pdfflagshow" @click.capture="iframeupload">预览</el-button>
                </el-col>
                <el-col :span="type ==6 && datalist.tasktypeCode == '071'?3:6" style="text-align: center">
                  <el-button type="text" @click="determine">保存</el-button>
                  <el-button type="text" @click="empty">取消</el-button>
                </el-col>
              </el-row>
            </div>
            <!-- <p>历史方案：</p> -->
            <div class="toview_main_history">
              <div class="toview_main_doctor" v-for="obj in type==6? datalist.tiJianResult :datalist.tiJianPlan" :key="obj.id">
                <el-row>
                  <el-col :span="6">{{ obj.checkTime }}</el-col>
                  <el-col :span="6" style="padding-right: 30px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ obj.reportName }}</el-col>
                  <el-col :span="6" style="padding-right: 30px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap" v-if="type ==6">{{ obj.planPrice }}</el-col>
                  <el-col :span="type ==6 && datalist.tasktypeCode == '071'?3:6" style="text-align: center; display: flex" class="history_media">
                    <!-- <div @click.capture="iframeclick(obj)" id="iframeId"> -->
                    <iframe :src="obj.pdf" alt="" frameborder="0" scrolling="no" ref="iframe" />
                    <!-- </div> -->
                    <el-button size="small" type="text" style="margin-right: 10px" v-show="obj.pdf" @click="obj.pdf?iframeclick(obj):''">查看</el-button>
                  </el-col>
                  <el-col :span="type ==6 && datalist.tasktypeCode == '071'?3:6" style="text-align: center">
                    <!-- <el-button @click="historysee(obj)">查看</el-button> -->
                    <el-button type="text" size="mini" @click="Delete(obj)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-row>
        </div>
      </el-card>
    </div>
    <!-- 查看 -->
    <el-dialog :visible.sync="dialogVisiblesee" width="40%" class="Visiblesee" fullscreen style="text-align: center; background-color: red">
      <video v-show="dialogfalg" :src="videosrc" controls style="width: 100%; height: 760px"></video>
      <iframe v-show="iframesrcfalg" :src="iframesrc" alt="" frameborder="0" style="width: 70%; height: 760px" scrolling="no" />
      <img :src="imagesSrc" alt="" v-show="imagesSrcshow" style="width: 60%; height: 760px">
    </el-dialog>
    <!-- 底部 -->
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="defineClick">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { fileuploadaction } from 'src/utils/cities'
import { postRequestAnthor } from '@/api/index'
export default {
  props: ['addNewdata'],
  data() {
    return {
      datalist: '',
      loading: true,
      dialogVisible: true,
      dialogVisiblesee: false,
      fileList: [],
      type: 1,
      inspectRemarks: '',
      DateTime: '',
      reportName: '',
      planPrice: '',
      Remarks: '',
      iframesrc: '',
      videosrc: '',
      imgsrc: '',
      seetime: '',
      inspectlist: '',
      falg: false,
      fileurl: '',
      // fileType: 1,
      fileuploadac: '',
      typelist: '',
      activeIndex: '1',
      headimg: '',
      plantype: '',
      showflag: true,
      showclose: false,
      dialogfalg: false,
      imagesSrcshow: false,
      iframesrcfalg: false,
      types: [1301, '医生方案'],
      inspectFlag: false,
      flagshow: true,
      pdfflagshow: true,
      doctorloading: false,
      doctor_loading: false,
      imagesSrc: '',
      videoCovershow: true,
      videoCover_loading: false,
      imgdisabled: true,
    }
  },
  created() {
    this.fileuploadac = `${fileuploadaction}?computerToken=${
      JSON.parse(localStorage.getItem('newToken')).computerToken
    }`
    this.lodadata()
    postRequestAnthor('dict/getDictByCategory', { dictionaryCategoryCode: '12' }).then(res => {
      this.inspectlist = res
      // console.log(res)
    })
    // 获取方案类型
    postRequestAnthor('dict/getDictByCategory', { dictionaryCategoryCode: '12' }).then(res => {
      this.typelist = res
      // console.log(res)
    })
  },
  methods: {
    //上传拦截
    fileBeforeAvatarUpload(file) {
      const isPDF = file.type === 'application/pdf'
      if (!isPDF) {
        this.$message.error('上传文件只能是 PDF 格式!')
      }
      return isPDF
    },
    // 获取页面数据
    lodadata() {
      postRequestAnthor('/planmanage/querysamplingresultinfo', { taskId: this.addNewdata.id })
        .then(res => {
          this.datalist = res
          this.imgsrc = res.customPicUrl
            ? res.customPicUrl
            : 'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'
          if (this.datalist.catalogCode == 'DP') {
            this.inspectFlag = true
          } else if (this.datalist.catalogCode == 'TJ') {
            if (this.datalist.tasktypeCode == '071') {
              this.$refs.kzMenu.activeIndex = '6'
              this.changeType(6)
            } else {
              this.$refs.kzMenu.activeIndex = '5'
              this.changeType(5)
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 关闭详情页
    handleClose() {
      this.$emit('closeToView')
    },
    // 添加方案
    showclick() {
      if (this.type == 6 && this.datalist.tasktypeCode != '071') {
        if (this.datalist.tiJianPlan.length == 0) {
          this.$message({
            message: '请上传体检报告后再上传体检结果方案',
            type: 'warning',
          })
          return
        }
      }
      if (this.type == 6 && this.datalist.tasktypeCode == '071') {
        if (this.datalist.tiJianResult.length > 0) {
          this.$message({
            message: '体检方案只能上传一个,请删除已上传方案后再重新上传',
            type: 'warning',
          })
          return
        }
      }
      ;(this.iframesrc = ''), (this.videosrc = ''), (this.showflag = false)
    },
    // 取消添加
    empty() {
      this.showflag = true
      this.DateTime = ''
      this.Remarks = ''
      this.inspectRemarks = ''
      this.reportName = ''
      this.planPrice = ''
      ;(this.iframesrc = ''),
        (this.videosrc = ''),
        (this.imagesSrc = ''),
        (this.pdfflagshow = true),
        (this.flagshow = true),
        (this.doctor_loading = false)
      this.doctorloading = false
    },
    //pdf上传时
    doctorprogress() {
      // this.doctor_loading = true
      this.doctorloading = true
    },
    // 视频上传时
    doctor_progress() {
      this.doctor_loading = true
    },
    // 视频封面上传时
    videoCover_progress() {
      this.videoCover_loading = true
    },
    // 文件上传成功回调
    uploadsuccess(file) {
      this.fileurl = file.data
      // console.log(this.type)
      if (this.type == 4) {
        if (file.data.slice(file.data.lastIndexOf('.')) == '.pdf') {
          this.pdfflagshow = false
          this.iframesrc = file.data
          // this.doctor_loading = false
          this.doctorloading = false
          this.$message({
            message: 'PDF文件上传成功',
            type: 'success',
          })
        } else {
          // this.doctor_loading = false
          this.doctorloading = false
          this.$message({
            message: '请上传PDF文件',
            type: 'warning',
          })
        }
      } else if (this.type == 5) {
        this.iframesrc = file.data
        this.doctor_loading = false
        this.doctorloading = false
        this.$message({
          message: 'PDF文件上传成功',
          type: 'success',
        })
      } else {
        if (file.data.slice(file.data.lastIndexOf('.')).toLowerCase() == '.mp4') {
          this.imgdisabled = false
          this.flagshow = false
          this.videosrc = file.data
          this.doctor_loading = false
          this.doctorloading = false
          this.$message({
            message: '视频上传成功',
            type: 'success',
          })
        } else if (file.data.slice(file.data.lastIndexOf('.')) == '.pdf') {
          this.pdfflagshow = false
          this.iframesrc = file.data
          this.doctor_loading = false
          this.doctorloading = false
          this.$message({
            message: 'PDF文件上传成功',
            type: 'success',
          })
        } else if (
          file.data.slice(file.data.lastIndexOf('.')) == '.png' ||
          file.data.slice(file.data.lastIndexOf('.')) == '.jpg'
        ) {
          this.videoCovershow = false
          this.imagesSrc = file.data
          this.doctor_loading = false
          this.doctorloading = false
          this.videoCover_loading = false
          this.$message({
            message: '视频封面上传成功',
            type: 'success',
          })
        } else {
          this.doctor_loading = false
          this.doctorloading = false
          this.$message({
            message: '请上传PDF文件或视频',
            type: 'warning',
          })
        }
      }
    },
    // 上传失败
    uploaderror() {
      this.doctor_loading = false
      this.doctorloading = false
      this.$message.error('请上传小于100M的视频')
    },
    // 查看PDF
    iframeclick(item) {
      this.showflag = true
      this.imagesSrcshow = false
      this.iframesrcfalg = true
      this.dialogfalg = false
      this.iframesrc = item.pdf
      this.dialogVisiblesee = true
    },
    // 查看视频
    videoclick(item) {
      this.showflag = true
      this.imagesSrcshow = false
      this.iframesrcfalg = false
      this.dialogfalg = true
      this.videosrc = item.video
      this.dialogVisiblesee = true
    },
    // 查看视频封面
    videoCoverclick(item) {
      this.showflag = true
      this.imagesSrcshow = true
      this.iframesrcfalg = false
      this.dialogfalg = false
      this.imagesSrc = item.videoCover
      this.dialogVisiblesee = true
    },
    //关闭查看
    // seeClose(){
    //   this.iframesrc = '',
    //   this.videosrc = '',
    //   this.imagesSrc=''
    // },

    // 上传视频查看
    videoupload() {
      this.dialogfalg = true
      this.imagesSrcshow = false
      this.iframesrcfalg = false
      this.dialogVisiblesee = true
    },
    // 视频封面查看
    videoCoverupload() {
      this.dialogfalg = false
      this.imagesSrcshow = true
      this.iframesrcfalg = false
      this.dialogVisiblesee = true
    },
    // 上传pdf查看
    iframeupload() {
      this.dialogfalg = false
      this.imagesSrcshow = false
      this.iframesrcfalg = true
      this.dialogVisiblesee = true
    },
    // 删除
    Delete(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该永久删除该条历史记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          postRequestAnthor('/planmanage/delete', { taskId: row.taskId, id: row.id }).then(res => {
            if (res == true) {
              this.$message({
                message: '删除成功',
                type: 'success',
              })
              this.lodadata()
            }
          })
        })
        .catch(() => {
          this.$message({
            message: '已取消删除',
            type: 'success',
          })
        })
    },
    // 保存
    async determine() {
      if (this.DateTime == '') {
        this.$message({
          message: '请填写日期',
          type: 'warning',
        })
      } else if (this.inspectRemarks == '' && this.type == 4 && this.datalist.catalogCode != 'DP') {
        this.$message({
          message: '请选择检查项目',
          type: 'warning',
        })
      } else if ((this.type == 6 && this.reportName == '') || (this.type == 5 && this.reportName == '')) {
        this.$message({
          message: this.type == 5 ? '请填写报告名称' : '请填写方案名称',
          type: 'warning',
        })
      } else if (this.planPrice == '' && this.type == 6 && this.datalist.tasktypeCode == '071') {
        this.$message({
          message: '请上填写方案价格',
          type: 'warning',
        })
      } else if ((this.iframesrc == '' && this.type == 5) || (this.type == 6 && this.iframesrc == '')) {
        this.$message({
          message: '请上传PDF',
          type: 'warning',
        })
      } else if (this.type != 4 && this.type != 5 && this.type != 6 && this.Remarks == '') {
        this.$message({
          message: '请填写备注',
          type: 'warning',
        })
      } else if (this.fileurl == '') {
        this.$message({
          message: '请上传PDF或视频',
          type: 'warning',
        })
      } else if (this.fileurl.slice(this.fileurl.lastIndexOf('.')).toLowerCase() == '.mp4' && this.imagesSrc == '') {
        this.$message({
          message: '请上传视频封面',
          type: 'warning',
        })
      } else {
        console.log(
          // this.addNewdata.id,
          this.types[0] == 1304 ? 1 : 2,
          // this.types[0],
          // this.types[1],
          // this.types[0] == 1304 ? this.inspectRemarks : '',
          // this.types[0] != 1304 ? this.Remarks : '',
          // this.DateTime,
          // this.inspectRemarks,
          this.types[0] == 1304 ? this.inspectRemarks : '',
          // this.iframesrc,
          // this.videosrc,
        )
        postRequestAnthor('/planmanage/addsave', {
          taskId: this.addNewdata.id,
          resultType: this.types[0] == 1304 || this.types[0] == 1305 ? 1 : 2,
          resultCode: this.types[0],
          resultName: this.types[1],
          // checkitemName: this.types[0] == 1304 ? this.inspectRemarks : '',
          remark: this.types[0] != 1304 ? this.Remarks : '',
          reportName:
            this.type == 5 || this.type == 6
              ? this.reportName
              : this.datalist.catalogCode == 'DP'
              ? this.inspectRemarks
              : '',
          planPrice: this.type == 6 ? this.planPrice : '',
          createBy: '2',
          checkTime: this.DateTime,
          schemeType: this.types[0] == 1304 ? (this.datalist.catalogCode == 'DP' ? '' : this.inspectRemarks) : '',
          pdf: this.iframesrc,
          video: this.videosrc,
          videoCover: this.imagesSrc,
          // fileType: this.fileType,
          // fileName: this.fileurl,
        }).then(res => {
          if (res == true) {
            this.lodadata()
            this.$message({
              message: '保存成功',
              type: 'success',
            })
            this.DateTime = ''
            this.Remarks = ''
            this.inspectRemarks = ''
            ;(this.iframesrc = ''), (this.videosrc = ''), (this.imagesSrc = ''), (this.showflag = true)
            ;(this.pdfflagshow = true), (this.flagshow = true)
          }
        })
      }
    },
    // 确定
    defineClick() {
      if (this.showflag == false) {
        this.determine()
      } else {
        this.dialogVisible = false
      }
    },
    // 点击显示指定方案
    changeType(num) {
      switch (num) {
        case 1:
          this.types = [1301, '医生方案']
          break
        case 2:
          this.types = [1302, '运动师方案']
          break
        case 3:
          this.types = [1303, '营养师方案']
          break
        case 4:
          this.types = [1304, '检查报告']
          break
        case 5:
          this.types = [1305, '体检报告']
          break
        case 6:
          this.types = [1306, '体检方案']
          break
      }
      // console.log(this.types);
      this.type = num
      this.DateTime = ''
      this.Remarks = ''
      this.inspectRemarks = ''
      this.reportName = ''
      this.planPrice = ''
      this.iframesrc = ''
      this.videosrc = ''
      this.showflag = true
      this.doctor_loading = false
      this.doctorloading = false
      ;(this.pdfflagshow = true), (this.flagshow = true)
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  display: flex !important;
  justify-content: space-between !important;
}
.organ-management-page {
  margin-top: -34px;
}
.upload {
  width: 100%;
  height: 200px;
  border: 1px solid #999;
  text-align: center;
  line-height: 200px;
}
.row {
  padding: 5px 0px;
  background-color: #fff;
  .row_head {
    padding: 10px;
    background-color: #eef1fc;
  }
  .row_add {
    .el-row {
      padding: 8px;
      border-top: 1px solid #eee;
    }
  }
}
.toview_top_container {
  display: flex;
  align-items: center;
  height: 100px;
  background-color: #fff;
  // text-align: center;
  margin: -20px;
  // padding: 10px;
  p {
    padding: 0 40px;
    flex: 1;
    // display: flex;
    span {
      flex: 1;
    }
  }
}
.history_media {
  display: flex;
  justify-content: center;
}
iframe,
video,
img {
  margin: -4px 5px;
  width: 30px;
  height: 40px;
  border: 0;
}
img {
  margin-right: 10px;
}
.Visiblesee {
  iframe,
  video {
    width: 400px;
    height: 400px;
  }
}
// 当期检查结果
.row_result {
  display: flex;
}
.dialog-footer {
  padding: 0 20%;
  display: flex;
  justify-content: center;
  margin-top: -20px;
}
.toview_main {
  background-color: #fff;
}
.toview_main_history {
  overflow: hidden;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  max-height: 190px;
  .el-row {
    padding: 8px;
    border-top: 1px solid #eee;
    .el-col {
      line-height: 28px;
      // text-align: center;
    }
  }
}
.el-menu--horizontal > .el-menu-item.is-active {
  color: #5479ff !important;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  background-color: #f7f8ff;
}
.el-menu--horizontal > .el-menu-item {
  color: #444 !important;
}
.el-menu {
  border: 0;
  .el-menu-item {
    height: 32px;
    line-height: 32px;
  }
}
.demo-form-inline {
  .el-menu-item,
  .el-submenu__title {
    border-radius: 0;
    padding: 0;
    margin: 10px;
  }
}
.el-menu {
  background-color: #f7f8ff !important;
}
.toview_top_container_img {
  line-height: 100px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #ccc;
}
</style>